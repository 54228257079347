<template>
  <div>
    <div class="box box--md box--white">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Fund Name</p>
                <div class="form-group">
                  <input
                    v-model="$v.localPlan.title.$model"
                    autofocus
                    type="text"
                    :class="['form-control form-control--auto', { 'form-control--error': $v.localPlan.title.$error }]"
                  />

                  <p v-if="$v.localPlan.title.$error" class="form-error-text">Title can't be blank</p>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Hold period (Months)</p>
                <div class="form-group">
                  <currency-input
                    v-model="$v.localPlan.period.$model"
                    target="numeric"
                    :class="[
                      'form-control form-control--auto',
                      { 'form-control--error': $v.localPlan.period.$anyError }
                    ]"
                  />

                  <p v-if="$v.localPlan.period.$anyError" class="form-error-text">
                    <span v-if="!$v.localPlan.period.numeric">Period should be a number</span>
                    <span v-if="!$v.localPlan.period.minValue">Period should larger than 0</span>
                  </p>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Interest Rate (%)</p>
                <div class="form-group">
                  <currency-input
                    v-model="$v.localPlan.rate.$model"
                    target="numeric"
                    :class="['form-control form-control--auto', { 'form-control--error': $v.localPlan.rate.$anyError }]"
                  />

                  <p v-if="$v.localPlan.rate.$anyError" class="form-error-text">
                    <span v-if="!$v.localPlan.rate.numeric">Rate should be a number</span>
                    <span v-if="!$v.localPlan.rate.minValue">Rate should larger than 0</span>
                  </p>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Minimum amount</p>
                <div class="form-group">
                  <currency-input
                    v-model="$v.localPlan.min_deposit_amount.$model"
                    target="numeric"
                    :class="[
                      'form-control form-control--auto',
                      { 'form-control--error': $v.localPlan.min_deposit_amount.$anyError }
                    ]"
                  />
                  <p v-if="$v.localPlan.min_deposit_amount.$anyError" class="form-error-text">
                    <span v-if="!$v.localPlan.min_deposit_amount.numeric">Minimum amount should be a number</span>
                    <span v-if="!$v.localPlan.min_deposit_amount.minValue">Minimum amount should larger than 0</span>
                  </p>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Maximum amount</p>
                <div class="form-group">
                  <currency-input
                    v-model="$v.localPlan.max_deposit_amount.$model"
                    target="numeric"
                    :class="[
                      'form-control form-control--auto',
                      { 'form-control--error': $v.localPlan.max_deposit_amount.$anyError }
                    ]"
                  />
                  <p v-if="$v.localPlan.max_deposit_amount.$anyError" class="form-error-text">
                    <span v-if="!$v.localPlan.max_deposit_amount.numeric">Maximum amount should be a number</span>
                    <span v-if="!$v.localPlan.max_deposit_amount.minValue">Maximum amount should larger than 0</span>
                  </p>
                  <p v-if="!$v.localPlan.max_deposit_amount.$anyError && !amountValidation" class="form-error-text">
                    Maximum amount should larger than Minimum amount
                  </p>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Minimum staked TOKO</p>
                <div class="form-group">
                  <currency-input
                    v-model="$v.localPlan.min_toko_amount.$model"
                    target="numeric"
                    :class="[
                      'form-control form-control--auto',
                      { 'form-control--error': $v.localPlan.min_toko_amount.$anyError }
                    ]"
                  />

                  <p v-if="$v.localPlan.min_toko_amount.$anyError" class="form-error-text">
                    <span v-if="!$v.localPlan.min_toko_amount.numeric">Minimum staked TOKO should be a number</span>
                    <span v-if="!$v.localPlan.min_toko_amount.minValue">Minimum staked TOKO should larger than 0</span>
                  </p>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Color</p>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="$v.localPlan.color.$model"
                    :class="[
                      'form-control form-control--auto',
                      { 'form-control--error': $v.localPlan.color.$anyError }
                    ]"
                  />
                  <p v-if="$v.localPlan.color.$error" class="form-error-text">Color can't be blank</p>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Dark Color (support for dark mode)</p>
                <div class="form-group">
                  <input
                    type="text"
                    v-model="$v.localPlan.color_dark.$model"
                    :class="[
                      'form-control form-control--auto',
                      { 'form-control--error': $v.localPlan.color_dark.$anyError }
                    ]"
                  />
                  <p v-if="$v.localPlan.color_dark.$error" class="form-error-text">Dark color can't be blank</p>
                </div>
              </div>
            </td>
            <td />
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Icon</p>
                <div class="form-group">
                  <img
                    :src="localPlan.icon_url ? localPlan.icon_url : '/images/img_no-image-available.png'"
                    width="100"
                    ref="iconPreview"
                  />
                  <p v-if="!plan && !localPlan.file && isOnSave" class="form-error-text">Icon can't be blank</p>

                  <div class="form-upload">
                    <input id="upload-icon" type="file" @change="setImageFile($event, { target: 'icon' })" />
                    <label for="upload-icon" class="btn btn--md btn--primary">Upload File</label>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Dark Icon (support for dark mode)</p>
                <div class="form-group">
                  <img
                    :src="localPlan.icon_dark_url ? localPlan.icon_dark_url : '/images/img_no-image-available.png'"
                    width="100"
                    ref="darkIconPreview"
                  />
                  <p v-if="!plan && !localPlan.file_dark_icon && isOnSave" class="form-error-text">
                    Dark Icon can't be blank
                  </p>

                  <div class="form-upload">
                    <input id="upload-dark-icon" type="file" @change="setImageFile($event, { target: 'darkIcon' })" />
                    <label for="upload-dark-icon" class="btn btn--md btn--primary">Upload File</label>
                  </div>
                </div>
              </div>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex mt-16">
      <button v-if="plan" class="btn btn--red-bordered" @click="isConfirmDeletePlan = true">Delete Fund</button>
      <span v-else></span>

      <div class="btn-group">
        <button class="btn btn--purple-bordered" @click="$emit('cancel')">Cancel</button>
        <button class="btn btn--primary" @click="onOpenSaveConfirm" :disabled="!isChanged">Save</button>
      </div>
    </div>

    <confirm-dialog
      v-if="isConfirmSavePlan"
      :open.sync="isConfirmSavePlan"
      confirmText="Are you sure ?"
      confirmButtonText="Save"
      @confirm="onEmitSave"
    />

    <confirm-dialog
      v-if="isConfirmDeletePlan"
      :open.sync="isConfirmDeletePlan"
      confirmText="Are you sure ?"
      confirmButtonText="Delete"
      @confirm="$emit('delete-plan')"
    />
  </div>
</template>

<script>
import { isObjEqual, deepCopy } from '@/utils/json-parser'
import fundFormValidator from '@/validations/fund-form'
import CurrencyInput from '@/components/CurrencyInput'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: 'FundForm',

  props: {
    plan: {
      type: Object,
      require: false
    }
  },

  mixins: [fundFormValidator],

  data() {
    return {
      localPlan: {
        color: '',
        color_dark: '',
        icon_dark_url: '',
        icon_url: '',
        max_deposit_amount: 0,
        min_toko_amount: 0,
        min_deposit_amount: 0,
        period: 0,
        rate: 0,
        title: '',
        file: '',
        file_dark_icon: ''
      },
      isChanged: false,
      isConfirmSavePlan: false,
      isConfirmDeletePlan: false
    }
  },

  components: {
    CurrencyInput,
    ConfirmDialog
  },

  methods: {
    getPlanDataRequest() {
      return {
        ...this.plan,
        ...this.localPlan
      }
    },

    onPreviewImage(file, target) {
      const imgEl = target === 'icon' ? this.$refs.iconPreview : this.$refs.darkIconPreview
      const srcImg = URL.createObjectURL(file)

      imgEl.src = srcImg
    },

    setImageFile(e, { target }) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      if (target === 'icon') {
        this.localPlan.file = files[0]
        this.onPreviewImage(files[0], target)
      } else {
        this.localPlan.file_dark_icon = files[0]
        this.onPreviewImage(files[0], target)
      }
    },

    onOpenSaveConfirm() {
      // trigger for display error on image
      this.isOnSave = true
      this.$v.$touch()
      if (!this.isFormValid) return

      this.isConfirmSavePlan = true
    },

    onEmitSave() {
      if (this.plan) {
        this.$emit('update-plan', this.getPlanDataRequest())
      } else {
        this.$emit('create-plan', this.getPlanDataRequest())
      }
    }
  },

  created() {
    if (this.plan) {
      this.localPlan = deepCopy(this.plan)
    }
  },

  watch: {
    localPlan: {
      handler(newValue) {
        this.isChanged = !isObjEqual(newValue, {
          ...this.plan,
          file: '',
          file_dark_icon: ''
        })
      },
      deep: true
    }
  }
}
</script>
