<template>
  <input v-model="formatValue" @blur="isInputActive = false" @focus="isInputActive = true" />
</template>

<script>
export default {
  name: 'FormatInput',

  props: {
    value: {
      type: [String, Number]
    }
  },

  data() {
    return {
      isInputActive: false
    }
  },

  computed: {
    formatValue: {
      get() {
        if (isNaN(this.value)) return 0
        return (
          this.value &&
          parseInt(this.value)
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        )
      },

      set(newValue) {
        const formatValue = parseFloat(newValue.replace(/[^\d.]/g, '')) || 0
        this.$emit('input', formatValue)
      }
    }
  }
}
</script>
