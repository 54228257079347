import { required, numeric, minValue } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      isOnSave: false
    }
  },

  computed: {
    isFormValid() {
      return !this.$v.localPlan.$anyError && this.amountValidation && this.imageValidation
    },

    amountValidation() {
      return this.localPlan.min_deposit_amount <= this.localPlan.max_deposit_amount
    },

    imageValidation() {
      if (this.plan) return true
      if (!this.localPlan.file || !this.localPlan.file_dark_icon) return false

      return true
    }
  },

  validations: {
    localPlan: {
      title: {
        required
      },

      period: {
        required,
        numeric,
        minValue: minValue(1)
      },

      rate: {
        required,
        numeric,
        minValue: minValue(1)
      },

      min_toko_amount: {
        required,
        numeric,
        minValue: minValue(1)
      },

      min_deposit_amount: {
        required,
        numeric,
        minValue: minValue(1)
      },

      max_deposit_amount: {
        required,
        numeric,
        minValue: minValue(1)
      },

      color: {
        required
      },

      color_dark: {
        required
      }
    }
  }
}
